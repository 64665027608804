export const Lideres = (props) => {
    return (
      <div id="lider">
        <div className="container">
          <div className="row">
            

            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="about-text">
                <h2>Mentoria para Líderes</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/lider.jpg" className="img-responsive" alt="" />{" "}
            </div>

          </div>
        </div>
      </div>
    );
  };
  