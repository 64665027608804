export const CORPORAL = (props) => {
    return (
      <div id="corporal">
        <div className="container">
          <div className="row">
            
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/yoga.jpg" className="img-responsive" alt="" />{" "}
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="about-text">
                <h2>Diagnóstico Corporal</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <h3>Ao passar pela análise você vai entender quatro questões que lhe deixam travado: </h3>
                <div className="list-style">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>   
              </div>
            </div>    
          </div>

          <div className="row">
            <div className="col-xs-12 col-md-6">
                <p><strong>Como funciona?</strong>{props.data ? props.data.paragraph3 : "loading..."}</p>
            </div>
    
            <div className="col-xs-12 col-md-6">
              <p><strong>Quem pode passar pela análise?</strong>{props.data ? props.data.paragraph2 : "loading..."}</p>
            </div>
            
          </div>

        </div>
      </div>
    );
  };
  