export const Misure = (props) => {
    return (
        <div id="misure">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img src="img/fibonacci.png" className="img-responsive" alt="" />{" "}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>Misure</h2>
                            <p>{props.data ? props.data.paragraph : "loading..."}</p>
                            <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                            <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
                            <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
                            <h3>Visão</h3>
                            <p>{props.data ? props.data.vision : "loading..."}</p>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-xs-3 col-md-12">
                        <div className="col-xs-12 col-md-6">
                            <h3>Missão</h3>
                            <p>{props.data ? props.data.mission : "loading..."}</p>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <h3>Negócio</h3>
                            <p>{props.data ? props.data.business : "loading..."}</p>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <h3>Valores</h3>
                                {props.data
                                    ? props.data.valores.map((d, i) => (
                                        <>
                                            <h5 className="misureValoresTitle" key={`${d}-${i}`}> {d.split(":")[0]}</h5>
                                            <p>{d.split(":")[1]}</p>
                                        </>
                                        
                                    ))
                                    : "loading"}  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
