export const Liderados = (props) => {
    return (
      <div id="liderados">
        <div className="container">
          <div className="row">

            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/liderados.jpg" className="img-responsive" alt="" />{" "}
            </div>            

            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="about-text">
                <h2>Mentoria para Liderados</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  };
  