export const DISC = (props) => {
    return (
      <div id="disc">
        <div className="container">
          
          <div className="row">  
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="about-text">
                <h2>Diagnóstico Comportamental - DISC</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/disc_2.jpeg" className="img-responsive" alt="" />{" "}
            </div>

          </div>

          <div className="row">
            <div className="about-text">
              <h3>Onde posso utilizar o DISC?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                  </ul>
                </div>
              </div>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                  </ul>
                </div>
              </div>
            </div> 
          </div>

          <div className="row about-text">
            <h4>Utilizando a metodologia DISC é possível com toda segurança identificar o perfil dominante de cada pessoa:</h4>
            <div className="col-xs-12 col-md-6">
                <p><strong>D - Dominância: </strong>{props.data ? props.data.paragraph3 : "loading..."}</p>
                <p><strong>I - Influência: </strong>{props.data ? props.data.paragraph4 : "loading..."}</p>
            </div>
    
            <div className="col-xs-12 col-md-6">
                <p><strong>S - Estabilidade: </strong>{props.data ? props.data.paragraph5 : "loading..."}</p>
                <p><strong>C - Conformidade: </strong>{props.data ? props.data.paragraph6 : "loading..."}</p>
            </div>

          </div>

        </div>
      </div>
    );
  };
  