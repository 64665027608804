import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Hero } from "./components/hero";
import { About } from "./components/about";
import { Misure } from "./components/misure";
import { Family } from "./components/family";
import { DISC } from "./components/disc";
import { CORPORAL } from "./components/corporal";
import { Lideres } from "./components/mentoriasLideres";
import { Liderados } from "./components/mentoriaLiderados";
import { Treinamentos } from "./components/treinamentos";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Hero data={landingPageData.Slider} />
      <Misure data={landingPageData.Misure}/>
      <About data={landingPageData.About} />
      <Family data={landingPageData.Family} />
      <Services data={landingPageData.Services} />
      <DISC data={landingPageData.DISC} />
      <CORPORAL data={landingPageData.Corporal} />
      <Lideres data={landingPageData.Lideres} />
      <Liderados data={landingPageData.Liderados} />
      <Treinamentos data={landingPageData.Treinamentos} />
      <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
