export const Family = (props) => {
    return (
      <div id="family">
        <div className="container">
          <div className="row">
            
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Sobre a Família</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                  <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/teste_familia.jpg" className="img-responsive" alt="" />{" "}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-md-12">
                <div className="about-text">
                  
                  <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
                  <p>{props.data ? props.data.paragraph5 : "loading..."}</p>
                  <strong><p>{props.data ? props.data.paragraph6 : "loading..."}</p></strong>
                  <strong><p>{props.data ? props.data.paragraph7 : "loading..."}</p></strong>
                </div>
            </div>
          </div>

        </div>
      </div>
    );
  };